<!--
 * @Description: 车牌登记 register
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-22 15:02:25
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>
    <van-nav-bar title="车牌登记"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="main-content">
      <div class="main-box">
        <div class="content">
          <van-row class="title">
            车牌图片
          </van-row>
          <van-uploader class="carBG"
                        v-model="fileList"
                        :after-read="afterRead"
                        :before-read="beforeRead"
                        @delete="delImg"
                        max-count="1">
            <div style="width:100%;height:100%">
              <van-image :src="require('@/assets/signIn/carimg.png')"></van-image>
            </div>
          </van-uploader>
          <van-row class="title">
            车牌颜色
          </van-row>
          <!-- 车牌颜色绑定弹出层 -->
          <van-field v-model="plateColor"
                     placeholder="请选择车牌颜色"
                     readonly
                     @click="plateColorClick" />
          <van-action-sheet v-model="showPlateColorDialog"
                            :actions="plateColorList"
                            @select="onSelectPlateColor" />
          <van-row class="title"
                   v-if="this.$route.query.page==='out'">
            泊位号
          </van-row>
          <van-field v-model="form.parkSpaceNumber"
                     placeholder="请选择泊位号"
                     clearable
                     @input="berthageInput"
                     @click="clickBerth"
                     v-if="this.$route.query.page==='out'" />
          <van-row class="selectorBerthage">
            <van-picker v-if="berthageDialog"
                        show-toolbar
                        title="选择泊位"
                        :columns="selectorBerthageList"
                        @cancel="berthageDialog = false"
                        @confirm="onSelectBerthage" />
          </van-row>
          <!-- 车牌号 -->
          <van-row class="title">车牌号</van-row>
          <div :class="!popShow? 'platInput-box':'platInput-box2'">
            <plate-number-input ref="numInput"
                                v-model="form.plateNumber"
                                @idxChange='handleClickChangeIndex'
                                @currentPlateNumber='handleShowPlateNumber' />
          </div>
        </div>
      </div>
      <div :class="popShow? 'pop-placeholder1':'pop-placeholder2'"></div>
      <div v-if="!berthageDialog"
           style="height:45px">
      </div>
    </div>
    <!-- 汉字字母键盘 -->
    <keyborad-button class="keyboradButton"
                     ref="keyboard"
                     v-show="popShow"
                     @handleClickClose="handleClickClose"
                     @click="handleKeyboardClick" />
    <div class="but-box"
         v-if="!berthageDialog">
      <van-button @click="queryParkType"
                  :disabled="buttonDisable"
                  :loading=buttonLoading
                  loading-type="spinner">确认</van-button>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import plateNumberInput from '@/components/plateNumber/plateNumberInput'
import keyboradButton from '@/components/plateNumber/keyboradButton'
import { Toast, Dialog } from 'vant'
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    plateNumberInput,
    keyboradButton
  },
  data () {
    // 这里存放数据
    return {
      fileList: [],
      popShow: false,
      plateColor: '', // 车牌颜色
      plateColorList: [],
      plateColorObj: {},
      showPlateColorDialog: false, // 车牌颜色选择弹窗
      berthageList: [], // 全部泊位号
      selectorBerthageList: [], // 选择器上的泊位号
      berthageDialog: false,
      form: {
        parkId: this.$cookie.get('managePark').manageParkId,
        plateNumber: '', // 车牌号
        parkSpaceNumber: '', // 泊位号
        numberPlateColorCode: '', // 车牌颜色编码
        evidenceImgUrl: ''
      },
      buttonDisable: true,
      buttonLoading: false,
      parkSpaceOrderStatus: '',
      arrearsMoney: '0元' // 欠费金额
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'form.plateNumber' () {
      if (this.form.plateNumber.length < 7) {
        this.buttonDisable = true
      }
      if (this.form.plateNumber.length >= 7) {
        this.buttonDisable = false
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.$route.query.parkSpaceNumber) {
      this.form.parkSpaceNumber = this.$route.query.parkSpaceNumber
    }
    this.getBerthage()
    this.getPlateColor()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // 监听安卓软键盘弹出导致的窗口大小变化
    const self = this
    window.onresize = function () {
      // 将滚动轴移到底部
      self.$nextTick(() => {
        const scoll = document.querySelector('.main-content')
        scoll.scrollTo(0, scoll.scrollHeight)
      })
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 判断是路内还是路外 out路内 in路外
    queryParkType () {
      this.buttonLoading = true
      if (this.$route.query.page === 'out') {
        if (!this.form.evidenceImgUrl) {
          Toast('请先上传车牌图片')
          this.buttonLoading = false
        } else if (!this.form.numberPlateColorCode) {
          Toast('请选择车牌颜色')
          this.buttonLoading = false
        } else if (!this.form.parkSpaceNumber) {
          Toast('请输入泊位号')
          this.buttonLoading = false
        } else if (!this.berthageList.includes(this.form.parkSpaceNumber)) {
          Toast('请输入正确的泊位号')
          this.buttonLoading = false
        } else if (!this.form.plateNumber) {
          Toast('请输入车牌号')
          this.buttonLoading = false
        } else if (this.form.plateNumber === 'fail') {
          Toast('车牌号不能有空格')
          this.buttonLoading = false
        } else if ((this.form.plateNumber.length === 8 && Number(this.form.numberPlateColorCode) !== 6)) {
          // 绿牌车选了其他颜色
          Toast('请选择正确的车牌颜色')
          this.buttonLoading = false
        } else if ((this.form.plateNumber.length === 7 && Number(this.form.numberPlateColorCode) === 6)) {
          //  不是绿牌车选了绿牌车的编码
          Toast('请选择正确的车牌颜色')
          this.buttonLoading = false
        } else {
          this.handleRoadConfirm()
        }
      } else if (this.$route.query.page === 'in') {
        if (!this.form.evidenceImgUrl) {
          Toast('请先上传车牌图片')
          this.buttonLoading = false
        } else if (!this.form.numberPlateColorCode) {
          Toast('请选择车牌颜色')
          this.buttonLoading = false
        } else if (!this.form.plateNumber) {
          Toast('请输入车牌号')
          this.buttonLoading = false
        } else if (this.form.plateNumber === 'fail') {
          Toast('车牌号不能有空格')
          this.buttonLoading = false
        } else if ((this.form.plateNumber.length === 8 && Number(this.form.numberPlateColorCode) !== 6)) {
          // 绿牌车选了其他颜色
          Toast('请选择正确的车牌颜色')
          this.buttonLoading = false
        } else if ((this.form.plateNumber.length === 7 && Number(this.form.numberPlateColorCode) === 6)) {
          //  不是绿牌车选了绿牌车的编码
          Toast('请选择正确的车牌颜色')
          this.buttonLoading = false
        } else {
          this.handleParkConfirm()
        }
      }
    },
    // 车行道确认
    handleRoadConfirm () {
      // 查询泊位有无订单
      const info = {
        parkSpaceNumber: this.form.parkSpaceNumber
      }
      this.$register.queryOrderOnlineByParkSpaceId(info).then(res => {
        if (res.resultEntity) {
          Toast('该泊位已有在停车辆')
          this.buttonLoading = false
        } else {
          // 泊位无订单登记车牌
          this.queryOnlineOrder()
        }
      }).catch(() => {
        this.buttonLoading = false
      })
    },
    // 人行道确认
    handleParkConfirm () {
      this.queryOnlineOrder()
    },
    // 查询车牌有无在线订单
    queryOnlineOrder () {
      Dialog.confirm({
        message: '确认登记车牌'
      }).then(() => {
        const info = {
          numberPlateColorCode: this.form.numberPlateColorCode,
          plateNumber: this.form.plateNumber
        }
        this.$register.getOrderOnlineByPlateNumber(info).then(res => {
          // 查询该车辆是否有在停订单
          if (res.resultEntity) {
            Dialog.alert({
              message: '查询到该车存在未离场记录，需要继续登记请联系中控进行解决'
            }).then(() => {
              this.buttonLoading = false
            })
          } else {
            this.registerPlateNumber()
          }
        }).catch(() => {
          this.buttonLoading = false
        })
      }).catch(() => {
        this.buttonLoading = false
        Toast('取消登记')
      })
    },
    // 登记车牌
    registerPlateNumber () {
      this.$register.registerOrder(this.form).then(() => {
        this.buttonLoading = false
        Toast('车辆登记成功')
        this.queryArrears()
      }).catch(() => {
        this.buttonLoading = false
      })
    },
    // 查询车牌欠费订单
    queryArrears () {
      const info = {
        numberPlateColorCode: this.form.numberPlateColorCode,
        plateNumber: this.form.plateNumber
      }
      this.$register.queryArrears(info).then(res => {
        this.arrearsMoney = Number(Number(res.resultEntity.arrearsMoney) / 100).toFixed(2) + '元'
        this.queryOrderDetails()
      }).catch(() => {
        this.buttonLoading = false
      })
    },
    // 查询车牌订单详情
    queryOrderDetails () {
      const info = {
        numberPlateColorCode: this.form.numberPlateColorCode,
        plateNumber: this.form.plateNumber
      }
      this.$register.queryOnlineOrderBySinglePlateNumber(info).then(res => {
        res.resultEntity.arrearsMoney = this.arrearsMoney
        this.handleClickPrint(res.resultEntity)
      }).catch(() => {
        this.buttonLoading = false
      })
    },
    // 点击打印
    handleClickPrint (item) {
      const tips =
        '1、请按照划线停车位依次顺向停放，计费结束以车辆离场为准，请及时缴纳停车费，以免对您的出行、征信造成不良的影响。\n' +
        '2、仅限公共资源占用费、不含车辆及贵重物品保管费、离车前关闭好车辆门窗，贵重物品随身携带，避免发生财产安全损失。\n' +
        '3、收费依据：重庆市物价局（渝价【2014】175号文件执行）。\n' +
        '4、管理单位：中交建筑集团重庆建设有限公司。\n' +
        '请使用微信或支付宝扫码支付，支付完成后可以在公众号渝悦停里开取电子发票\n' +
        '客服热线：4001101699\n'
      window.android.printTicket2(
        '渝北区路侧停车登记小票',
        item.parkName,
        item.parkSpaceNumber,
        item.plateNumber,
        localStorage.getItem('userName'),
        item.entranceTime,
        item.arrearsMoney,
        'https://officialaccount.cqybxm.com/#/?page=alipayCode&orderSequence=' + item.orderSequence,
        tips,
        '做文明渝北人，请勿随地丢弃小票'
      )
      this.$router.go(-1)
      // 'https://cqpark-test.datatachina.com/official/#/?page=alipayCode&orderSequence=' + item.orderSequence,
      // 'https://officialaccount.cqybxm.com/#/?page=alipayCode&orderSequence=' + item.orderSequence,
    },
    // 车牌颜色选择器点击
    plateColorClick () {
      this.showPlateColorDialog = true
      this.popShow = false
      this.berthageDialog = false
    },
    // 选择车牌颜色
    onSelectPlateColor (color) {
      this.showPlateColorDialog = false
      this.plateColor = color.name
      this.form.numberPlateColorCode = color.code
    },
    // 选择泊位号
    onSelectBerthage (berthage) {
      if (berthage) {
        this.form.parkSpaceNumber = berthage
        this.berthageDialog = false
      } else {
        this.$toast('请选择泊位号')
      }
    },
    // 泊位选择器筛选
    berthageInput (val) {
      this.selectorBerthageList = this.berthageList.filter((item) => {
        return item.toLowerCase().includes(val.toLowerCase())
      })
    },
    // 文件读取完成
    afterRead (file) {
      if (file instanceof Array) {
        file = file[0]
      }
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$register.upFile(formData).then(res => {
            file.status = 'done'
            self.form.evidenceImgUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上传失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },
    // 文件读取前
    beforeRead (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const res = window.android.startRecognizeWithImage(reader.result)
        if (res !== 'error') {
          this.form.plateNumber = res
          this.form.plateNumber.split('').forEach(item => {
            this.handleKeyboardClick(item)
          })
          if (this.form.plateNumber.trim().length === 7) {
            this.plateColor = '蓝色'
            this.form.numberPlateColorCode = 2
          } else if (this.form.plateNumber.trim().length === 8) {
            this.plateColor = '绿色'
            this.form.numberPlateColorCode = 6
          }
        } else {
          this.$toast('请重新识别')
        }
      }
      reader.onerror = error => {
        console.log(error)
      }
      return true
    },
    // 图片删除
    delImg () {
      this.form.evidenceImgUrl = ''
    },
    clickBerth () {
      this.berthageDialog = true
      this.popShow = false
      this.showPlateColorDialog = false
      // 将滚动轴移到底部
      this.$nextTick(() => {
        const scoll = document.querySelector('.main-content')
        scoll.scrollTo(0, scoll.scrollHeight)
      })
    },
    // 关闭车牌
    handleClickClose () {
      this.popShow = false
    },
    // 获取到车牌的值
    handleShowPlateNumber (val) {
      this.form.plateNumber = val
    },
    // 获取车牌输入框的index
    handleClickChangeIndex (val) {
      this.popShow = true
      this.$refs.keyboard.handleTypeChange(val)
    },
    // 键盘点击事件
    handleKeyboardClick (value) {
      this.$refs.numInput.setInput(value) // 将点击的值传入父组件
    },
    // 获取车牌颜色
    getPlateColor () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$register.queryPlateColor(info).then((response) => {
        // this.plateColorList = response.resultEntity
        // response.resultEntity.forEach(item => {
        //   this.plateColorObj[item.code] = item.name
        // })
        this.plateColorList = response.resultEntity.filter(item => {
          if (item.code !== '4' && item.code !== '5') {
            return item
          }
        })
      })
    },
    // 获取泊位号
    getBerthage () {
      const info = {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId
      }
      this.$homePage.queryParkLayerList(info).then(res => {
        const berthageList = []
        res.resultEntity.forEach(item => {
          berthageList.push(item.parkSpaceId)
        })
        this.berthageList = berthageList
        this.selectorBerthageList = berthageList
      })
    },
    // 返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  width: 100%;
  height: 100%;
  user-select: none;
  overflow-x: hidden;
  .main-content {
    width: 100%;
    height: calc(100vh - @navHeight - 50px);
    overflow: auto;
    .main-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        margin-top: 10px;
        padding-bottom: 20px;
        border-radius: 5px;
        background: #ffffff;
        .title {
          width: 95%;
          margin-top: 13px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        .carBG {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          width: 95%;
          height: 245px;
          background: #e7eaee;
          border-radius: 5px;
          overflow: hidden;
          .van-image {
            width: 150px;
            height: 100px;
            margin: 80px 0;
          }
        }
        /deep/ .van-cell {
          width: 95%;
          margin-top: 10px;
          border-radius: 5px;
          background-color: #e7eaee;
        }
        .platInput-box {
          width: 95%;
          margin-top: 20px;
        }
        .platInput-box2 {
          position: fixed;
          width: 100%;
          bottom: 300px;
          margin-top: 10px;
          padding: 10px;
          background: #ffffff;
          box-sizing: border-box;
        }
        .platInput-title {
          position: fixed;
          bottom: 315px;
          width: 95%;
          padding: 10px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
          background: #ffffff;
          border-bottom: 1px solid rgb(236, 236, 236);
          z-index: 100;
        }
        .plateColor {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          padding-left: 10px;
          box-sizing: border-box;
          line-height: 40px;
          color: #333333;
          font-size: 15px;
          background: #f5f8fc;
          border-radius: 5px;
        }
        .parkNum {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          background: #f5f8fc;
          border-radius: 5px;
        }
        .selectorBerthage {
          width: 95%;
          margin-top: 10px;
          border-radius: 5px;
          box-shadow: 0px 0px 5px 1px rgb(231, 231, 231);
          overflow: hidden;
        }
      }
    }
  }
  .but-box {
    // position: fixed;
    // bottom: 0px;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .van-button {
      width: 93.6%;
      margin-left: 3.2%;
      color: #ffffff;
      font-size: 17px;
      border-radius: 5px;
      background: #19a9fc;
    }
  }
  .keyboradButton {
    width: 98%;
    height: 250px;
    position: absolute;
    bottom: 50px;
    border-radius: 15px;
    background: #e2e3e7;
    display: block;
    margin: 0 auto;
  }
}
.pop-placeholder1 {
  height: 200px;
}
.pop-placeholder2 {
  height: 0px;
}
/deep/.van-uploader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/deep/.van-uploader__preview-delete {
  width: 20px;
  height: 20px;
}
/deep/.van-uploader__preview-delete-icon {
  top: 0;
  right: 0;
}
</style>
